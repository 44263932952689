const Home = () => {
  return (
    <div
      className="w-full  bg-fixed bg-cover bg-center h-screen flex items-center justify-center gap-3"
      style={{
        backgroundImage: `url('${
          window.innerWidth >= 1024 ? "/assets/main/heroPoster.png" : "/assets/main/mblPoster.png"
        }')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: window.innerWidth >= 1024 ? "fixed" : "scroll",
      }}
    >
      <div className="text-center px-8 mt-4">
        <p className="text-[#0D0D2B] font-playfair text-[30px] sm:text-[24px] md:text-[36px] lg:text-[48px] font-semibold leading-tight">
          Transforming
          <span className="font-seaweed text-[#187CFF]"> Healthcare </span>
          with Cutting-Edge
          <br className="hidden sm:block" /> Technology.
        </p>
        {/* <p className="text-[#3C4959] font-montserrat font-semibold text-[12px] sm:text-[12px] md:text-[14px] lg:text-[16px] mt-2 sm:mt-4 leading-relaxed">
          We use only the best quality materials on the market in order to provide the
          <br className="hidden sm:block" />
          best products to our patients. So don’t worry about anything and book yourself.
        </p> */}
      </div>
    </div>
  );
};

export default Home;
