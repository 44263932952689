import { LuAmpersand } from "react-icons/lu";

const WhyChoose = () => {
  const data = [
    {
      id: 1,
      text: "Expertise in the latest technologies, including MERN Stack, Artificial Intelligence, and Cloud Computing.",
    },
    {
      id: 2,
      text: "Commitment to privacy and ethical data handling, ensuring your information is always secure.",
    },
    {
      id: 3,
      text: "A relentless focus on innovation and delivering real value to healthcare providers and patients.",
    },
  ];

  return (
    <div className="mt-40 ">
      <div className="flex flex-col md:flex-row justify-center items-center mt-2 space-y-6 md:space-y-0 md:space-x-10">
        <div>
          <p className="text-[24px] ml-8 md:ml-8 lg:ml-8 md:text-[36px]  font-semibold font-playfair text-[#187CFF] text-left md:text-left">
            Why Choose Us?
          </p>
          {data.map((item) => (
            <div key={item.id} className="flex items-start ml-8  mt-3 space-x-2 md:space-x-2">
              <img
                src="/assets/main/check.png"
                alt="Check"
                className="h-4 w-4 md:h-4 md:w-4 lg:h-4 lg:w-4"
              />
              <p className="text-sm md:text-base font-montserrat font-semibold  max-w-[300px] md:max-w-[600px] lg:max-w-[500px]  mt-[-3px]">
                {item.text}
              </p>
            </div>
          ))}
        </div>

        <div className="ml-30">
          <img
            src="/assets/main/candid.png"
            alt="candid"
            className="h-[207px] w-[294px] md:h-72 md:w-96 mx-auto md:mx-0 "
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row mt-16 md:mt-40 bg-[#F3F5FBE0] rounded-2xl p-12 items-center md:items-start space-y-6 md:space-y-0 md:space-x-10">
        <div>
          <img
            src="/assets/main/group.png"
            alt="group"
            className="h-40 w-64 md:h-56 md:w-72 mx-auto md:ml-10"
          />
        </div>

        <div className="">
          <p className="text-[24px] md:text-[36px] font-semibold font-playfair text-[#0D0D2B] flex justify-center md:justify-start lg:justify-start items-center">
            Privacy
            <i className="mx-2">
              <LuAmpersand />
            </i>
            Security
          </p>

          <div className="mt-6 space-y-4">
            <p className="text-[12px] md:text-base font-montserrat font-semibold  max-w-[400px] md:max-w-[600px] mx-auto md:mx-0">
              At RIZZ Software Solutions, your trust is our foundation. We prioritize privacy in
              everything we do, ensuring that your personal and health information remains secure
              and confidential.
            </p>
            <p className="text-[12px] md:text-base font-montserrat font-semibold  max-w-[400px] md:max-w-[600px] mx-auto md:mx-0">
              We never share or sell your data without your explicit consent. All health information
              is used solely to provide the highest standard of care to patients, reflecting our
              unwavering commitment to ethical practices and data security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
