const Services = () => {
  const data = [
    {
      id: 1,
      img: "/assets/main/custum.png", // Correct path for public folder
      title: "Custom Software Development",
      content: "Tailored solutions for unique healthcare challenges",
    },
    {
      id: 2,
      img: "/assets/main/apps.png", // Correct path for public folder
      title: "Mobile Applications",
      content: "Intuitive, cross-platform apps for seamless healthcare access",
    },
    {
      id: 3,
      img: "/assets/main/cloud.png", // Correct path for public folder
      title: "AI-Driven Insights",
      content: "Unlock actionable insights from complex data",
    },
  ];

  return (
    <div className="bg-[#E9E9F44D] flex items-start w-screen ">
      <div className="min-h-screen flex items-center justify-start  py-10 w-full">
        <div className="text-center w-full px-4 md:px-8 lg:px-8">
          <p className="text-[26px] md:text-[42px] lg:text-[42px]  font-semibold font-playfair text-[#0D0D2B] mb-12">
            Our Services
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {data.map((item) => (
              <div
                key={item.id}
                className="max-w-xs bg-white p-4 md:p-6 lg:p-6 rounded-2xl shadow-xl mx-2 my-2 flex flex-col items-center"
              >
                <img
                  src={item.img}
                  alt={item.title}
                  className="w-14 h-14 object-contain mb-4 mt-2"
                />
                <div>
                  <p className="text-[18px] font-bold text-center font-rubik mt-4">{item.title}</p>
                </div>
                <p className="text-[12px] font-montserrat font-semibold max-w-30 mt-0 md:mt-4 lg:mt-2">
                  {item.content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
