import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navBar";
import Home from "./components/home";
import About from "./components/about";
import Services from "./components/services";
import WhyChoose from "./components/whyChoose";
import Mission from "./components/mission";
import Contact from "./components/contact";
import TermsAndConditions from "./toc/terms";
import Privacy from "./toc/privacyPolicy";
import "bootstrap/dist/css/bootstrap.min.css";
import Products from "./components/products";

const App = () => {
  const location = useLocation();

  const hideNavBarRoutes = ["/terms", "/privacyPolicy"];

  return (
    <div className="scroll-smooth">
      {/* Render NavBar conditionally */}
      {!hideNavBarRoutes.includes(location.pathname) && <NavBar />}
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-x-hidden">
              <section
                id="home"
                className="min-h-screen w-full bg-gray-100 flex justify-center items-center"
              >
                <Home />
              </section>
              <section
                id="products"
                className="min-h-screen w-full bg-[#E9E9F44D] flex justify-center items-center"
              >
                <Products />
              </section>

              <section id="about" className="min-h-[85vh] w-full bg-[#fff] flex items-center">
                <About />
              </section>
              <section
                id="services"
                className="min-h-screen w-full bg-[#E9E9F44D] flex justify-center items-center"
              >
                <Services />
              </section>
              <section
                id="whyChoose"
                className="min-h-screen w-full bg-white flex flex-col items-center "
              >
                <WhyChoose />
              </section>
              <section
                id="mission"
                className="min-h-screen w-full bg-white flex justify-center items-center"
              >
                <Mission />
              </section>

              <section
                id="contact"
                className="min-h-screen w-full bg-white flex justify-center items-start px-4 sm:px-8"
              >
                <Contact />
              </section>
            </div>
          }
        />

        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacyPolicy" element={<Privacy />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
