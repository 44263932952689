import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";

const Contact = () => {
  const images = [
    { id: 1, img: "/assets/main/facebook.png", link: "https://facebook.com" },
    { id: 2, img: "/assets/main/instagram.png", link: "https://instagram.com" },
    { id: 3, img: "/assets/main/youtube.png", link: "https://youtube.com" },
    { id: 4, img: "/assets/main/linkedin.png", link: "https://linkedin.com" },
    { id: 5, img: "/assets/main/twitter.png", link: "https://twitter.com" },
  ];

  return (
    <div>
      <div className="flex flex-col  items-center justify-center text-center mt-40 px-4 ">
        <p className="text-[26px] md:text-[36px] lg:text-[36px] font-semibold font-playfair text-[#187CFF]">
          We Implement
        </p>
        <p className=" text-[12px] font-semibold max-w-[1200px] font-montserrat mt-4 text-sm md:text-base">
          Next generation technologies in the products we develop and this can be extended as a
          service to our customers as well.
        </p>

        <img
          src="/assets/main/MERN.png"
          alt="MERN"
          className="
    h-auto 
    w-auto 
    max-h-100 md:max-h-44 lg:max-h-24 md:max-h-24
    max-w-[100%] 
    mt-10 
    object-contain 
    object-cover 
    sm:max-w-[80%] sm:max-h-28 mb-20"
        />
      </div>

      <footer className="bg-[#0D0D2B] text-white py-4 px-4 mt-20 w-full w-screen">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-center gap-2 mx-4 mt-10">
          <div className="flex flex-col space-y-6 md:w-1/2 ml-2 md:ml-8 lg:ml-8">
            <div className="bg-white rounded-full flex items-center justify-center w-fit p-2">
              <img
                src="/assets/main/rizzLogo1.png"
                alt="Rizz Logo"
                className="w-[135px] h-[29px]"
              />
            </div>

            <div>
              <h3 className="text-lg font-semibold font-lora mb-4">Our Address</h3>
              <div className="flex flex-row gap-2">
                <IoLocationOutline size={16} />
                <div>
                  <p className="text-[12px] font-montserrat">
                    Level 8 & 9, Olympia Teknos,
                    <br />
                    No 28, SIDCO Industrial Estate,
                    <br />
                    Guindy, Chennai – 600032.
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <FiPhoneCall size={14} />
                <div>
                  <p className="text-[12px] font-montserrat">
                    +91 94453 18886 <br />
                    +91 94453 18887
                  </p>
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              {images.map((item) => (
                <a
                  key={item.id}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center items-center bg-gray-800 rounded-full p-2 hover:bg-gray-700 transition"
                >
                  <img src={item.img} alt="social icon" className="h-6 w-6 object-contain" />
                </a>
              ))}
            </div>
          </div>

          <div className="md:w-1/2 mr-2 md:mr-8 lg:mr-8 mt-3 lg:mt-0 lg:mt-0">
            <h3 className="text-lg font-semibold font-lora mb-4">Contact Us</h3>
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Your Full Name"
                className="w-full bg-[#0D0D2B] border border-gray-700 rounded-md px-4 py-2 text-sm text-white placeholder-gray-500 focus:outline-none focus:border-blue-500"
              />
              <input
                type="email"
                placeholder="Your Mail ID"
                className="w-full bg-[#0D0D2B] border border-gray-700 rounded-md px-4 py-2 text-sm text-white placeholder-gray-500 focus:outline-none focus:border-blue-500"
              />
              <textarea
                placeholder="Your message"
                className="w-full bg-[#0D0D2B] border border-gray-700 rounded-md px-4 py-2 text-sm text-white placeholder-gray-500 focus:outline-none focus:border-blue-500 h-24"
              ></textarea>
              <div className="flex justify-center  md:justify-end lg:justify-end mt-4">
                <button
                  type="submit"
                  className="bg-white text-black px-4 py-1 rounded-md hover:bg-gray-300 transition"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
        <p className="w-full mt-6 md:w-auto text-center text-[10px] md:text-[14px] lg:text-[14px] text-white font-montserrat flex flex-col md:flex-row md:items-center md:justify-center space-y-2 md:space-y-0 md:space-x-6">
          <a href="/terms" className="text-white text-[12px]  no-underline">
            Terms and Conditions
          </a>
          <a href="/privacyPolicy" className="text-white text-[12px] no-underline">
            Privacy Policy
          </a>
          <span className="text-[12px] ">All rights reserved © rizz.com</span>
        </p>
      </footer>
    </div>
  );
};

export default Contact;
