import React from "react";

const About = () => {
  return (
    <div className="relative w-full bg-fixed bg-center bg-cover bg-[#fff]  md:l-20 lg:ml-20 ">
      {/* Background Image Section */}
      <div
        style={{
          backgroundImage: "url('/assets/main/map.png')",
          backgroundPosition: "center",
          backgroundPositionY: "20px",
        }}
        className="h-full w-full  bg-contain bg-no-repeat bg-contain flex justify-center items-center mx-auto"
      >
        <div className="px-4 sm:px-8 lg:px-16  md:text-left max-w-[1200px]">
          <p className="text-[26px] md:text-[42px] lg:text-[42px] font-semibold font-playfair text-[#187CFF]">
            Who We Are?
          </p>

          <p className="mt-6 text-[14px] md:text-[16px] text-[#1B1330] font-montserrat font-semibold leading-relaxed">
            At RIZZ Software Solutions Private Limited, we are transforming the healthcare industry
            through cutting-edge technology and a steadfast commitment to innovation.
          </p>

          <p className="mt-6 text-[14px] md:text-[16px] text-[#1B1330] font-montserrat font-semibold  leading-relaxed">
            As a software product and service company, we specialize in creating advanced,
            patient-centric solutions designed to make a meaningful impact. Leveraging a robust
            technology stack that includes MERN Stack, React Native, Artificial Intelligence, and
            Cloud Computing, we develop intelligent, scalable, and user-friendly systems tailored to
            meet the dynamic needs of healthcare providers and patients alike.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
