const Products = () => {
  return (
    <div
      className="flex flex-col justify-center items-center shadow-md rounded-2xl p-8 md:p-10 lg:p-12 bg-gray-50 w-[80%] "
      style={{
        backgroundColor: "rgba(233, 233, 244, 0.30)",
      }}
    >
      {/* Title */}
      <p className="text-2xl md:text-4xl lg:text-4xl font-semibold font-playfair text-gray-900 mb-8 text-center">
        Products
      </p>

      {/* Product Card */}
      <div className="flex flex-col md:flex-row lg:flex-row bg-white text-left sm:text-center md:text-left lg:text-left shadow-md rounded-[20px] justify-center items-center p-6 md:p-10 gap-4 md:gap-6">
        <img
          src="/assets/main/product1.png"
          alt="Product"
          className="w-[80px] sm:w-[80px] md:w-[100px] lg:w-[140px] h-auto mx-4 md:mx-4"
        />
        <p className="text-[12px] sm:text-[14px] md:text-[14px] font-semibold lg:text-[16px] text-gray-700 font-montserrat max-w-[400px] md:max-w-[600px] mx-auto md:mx-0">
          Our Home Healthcare App bridges the gap between <br />
          patients and healthcare professionals, allowing easy <br />
          access to skilled nursing and physiotherapy services <br />
          at home. Patients can book appointments tailored to <br />
          their schedule and needs, while healthcare providers <br />
          benefit from a reliable platform to grow their reach
          <br /> and offer flexible in-home care.
        </p>
      </div>
    </div>
  );
};

export default Products;
