import React from "react";

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-4">
      <div className="flex justify-center items-center border border-black rounded-2xl max-w-[80px] ml-10 h-[30px]">
        <a href="/#home" className="text-center text-[12px] text-[#187CFF] block no-underline">
          Go Home
        </a>
      </div>

      <div className="pt-4 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-lg p-8">
          <img
            src="/assets/main/rizzLogo.png"
            alt="Rizz Logo"
            className="w-[208px] h-[44px] mb-4"
          />
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">Privacy Policy</h1>
          <p className="text-sm text-gray-600 mb-8">Effective Date: 12th December 2024</p>

          <div className="prose prose-blue max-w-none">
            <p className="mb-6">
              Rizz Software Solutions Private Limited (hereinafter referred to as "we," "our," or
              "us") is committed to protecting your privacy. This Privacy Policy explains how we
              collect, use, disclose, and safeguard your information when you use our Websites,
              Mobile Applications, APIs, Products, and Services (collectively, the "Platform"). By
              accessing or using the Platform, you consent to the practices described in this
              Privacy Policy.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>

            <h3 className="text-xl font-semibold mt-6 mb-3">1. From Patients:</h3>
            <div className="ml-6">
              <h4 className="font-semibold mb-2">Personal Information:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Name, Gender, Date of Birth</li>
                <li>Relationship to the patient</li>
                <li>Address details (Lines 1 & 2, Pin Code, State, Landmark)</li>
                <li>Location</li>
                <li>Copy of Aadhaar card</li>
              </ul>

              <h4 className="font-semibold mb-2">Contact Person Details:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Name, Gender</li>
                <li>Address details (Lines 1 & 2, Pin Code, State)</li>
                <li>Copy of Aadhaar card</li>
              </ul>

              <h4 className="font-semibold mb-2">Health Information:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Pre-existing conditions</li>
                <li>Allergies</li>
                <li>
                  Medical history (Diagnosis, Treatment, Current Status, Diagnosed Date, Hospital
                  Name)
                </li>
                <li>
                  Family medical history (Relation, Medical Condition, Age at Diagnosis, Lifestyle
                  Factors)
                </li>
              </ul>
            </div>

            <h3 className="text-xl font-semibold mt-6 mb-3">2. From Healthcare Providers:</h3>
            <div className="ml-6">
              <h4 className="font-semibold mb-2">Personal Information:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Name, Gender, Date of Birth</li>
                <li>Copy of Nursing Certificate</li>
                <li>Copy of Aadhaar card</li>
              </ul>

              <h4 className="font-semibold mb-2">Emergency Contact Details:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Two contact names and numbers</li>
              </ul>

              <h4 className="font-semibold mb-2">Professional Details:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Work experience (Years, Recent Location, Hospital Name, Start/End Dates)</li>
                <li>Copy of experience certificate</li>
              </ul>

              <h4 className="font-semibold mb-2">Availability:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Joining Date</li>
                <li>Preferred Duty Time</li>
                <li>Desired Fees</li>
              </ul>

              <h4 className="font-semibold mb-2">Services Provided:</h4>
              <ul className="list-disc ml-6 mb-4">
                <li>Types of services offered</li>
                <li>Details of services provided while on duty</li>
                <li>Service location</li>
              </ul>
            </div>

            <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
            <ul className="list-decimal ml-6 mb-6">
              <li>To provide and manage our Platform's functionality and services</li>
              <li>To connect patients with healthcare providers</li>
              <li>To ensure compliance with legal and regulatory obligations</li>
              <li>To improve the quality and user experience of our Platform</li>
              <li>
                To send administrative information, such as updates to terms, conditions, and
                policies
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-8 mb-4">How We Share Your Information</h2>
            <p className="mb-6">
              We do not sell or share your personal data without your explicit consent. We may
              disclose your information in the following circumstances:
            </p>
            <ul className="list-disc ml-6 mb-6">
              <li>
                <strong>With Service Providers:</strong> To assist in delivering the services we
                provide.
              </li>
              <li>
                <strong>For Legal Obligations:</strong> When required to comply with applicable
                laws, regulations, or legal processes.
              </li>
              <li>
                <strong>With Your Consent:</strong> When you explicitly agree to share your
                information.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Data Security</h2>
            <p className="mb-6">
              We implement robust technical, administrative, and physical safeguards to protect your
              data from unauthorized access, disclosure, or misuse. However, no method of
              transmission over the internet or electronic storage is entirely secure. We cannot
              guarantee absolute security.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights</h2>
            <p className="mb-6">
              Depending on your jurisdiction, you may have the following rights regarding your
              personal information:
            </p>
            <ul className="list-disc ml-6 mb-6">
              <li>The right to access, correct, or delete your personal data</li>
              <li>The right to withdraw consent where processing is based on consent</li>
              <li>The right to data portability</li>
              <li>The right to lodge a complaint with a data protection authority</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Children’s Privacy</h2>
            <p className="mb-6">
              Our Platform is not intended for individuals under 13 years of age. Parents or
              guardians must provide verifiable consent for children to use the Platform.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to This Privacy Policy</h2>
            <p className="mb-6">
              We may update this Privacy Policy from time to time. Any changes will be posted on
              this page with an updated “Effective Date.” Your continued use of the Platform
              constitutes acceptance of the updated policy.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold mb-2">Rizz Software Solutions Private Limited</h3>
              <p>Level 8 & 9 Olympia Teknos,</p>
              <p>No. 28 SIDCO Industrial Estate,</p>
              <p>Guindy, Chennai 600032, India</p>
              <p className="mt-4">
                <strong>Email:</strong> support@rizzsoftware.com
              </p>
              <p>
                <strong>Phone:</strong> (+91) 9445318886
              </p>
              <p className="ml-14"> (+91) 9445318887</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
